import { LeagueTypes } from '../../utils/types';

const { UT_ADULT_TEAM_TENNIS, UT_JUNIOR_TEAM_TENNIS, UT_ADULT_TEAM_PICKLEBALL, UT_JUNIOR_TEAM_PICKLEBALL } = LeagueTypes;

export const fullWidthNavItems = [
    {
        label: 'Players',
        filterType: 'players',
        sportType: 'tennis,pickleball'
    },
    {
        label: 'All events',
        filterType: 'events',
        sportType: 'tennis,pickleball'
    },
];

export const tennisGlobalNavItems = [
    {
        label: 'All tennis events',
        filterType: 'events',
        sportType: 'tennis'
    },
    {
        label: 'Flex leagues',
        filterType: 'flexLeagues',
        sportType: 'tennis',
    },
    {
        label: 'Adult team tennis',
        filterType: UT_ADULT_TEAM_TENNIS,
        sportType: 'tennis',
        isLeagues: true,
    },
    {
        label: 'Junior team tennis',
        filterType: UT_JUNIOR_TEAM_TENNIS,
        sportType: 'tennis',
        isLeagues: true,
    },
    {
        label: 'UTR pro tennis tour',
        filterType: 'proTennisTour',
        sportType: 'tennis',
    },
    {
        label: 'Paid hits',
        filterType: 'paidHits',
        sportType: 'tennis',
    },
    {
        label: 'Paid hitters',
        filterType: 'paidHitClubs',
        sportType: 'tennis',
    },
    {
        label: 'Junior circuits',
        filterType: 'juniorCircuits',
        sportType: 'tennis',
    },
    {
        label: 'College circuits',
        filterType: 'collegeCircuits',
        sportType: 'tennis',
    },
    {
        label: 'Junior regionals',
        filterType: 'juniorRegionals',
        sportType: 'tennis',
    },
    {
        label: 'Camps',
        filterType: 'camps',
        sportType: 'tennis',
    },
    {
        label: 'Clubs',
        filterType: 'clubs',
        sportType: 'tennis,pickleball',
    },
    {
        label: 'High schools',
        filterType: 'highSchools',
        sportType: 'tennis',
    },
    {
        label: 'Colleges',
        filterType: 'colleges',
        sportType: 'tennis',
    },
];

export const pickleballGlobalNavItems = [
    {
        label: 'All pickleball events',
        filterType: 'events',
        sportType: 'pickleball'
    },
    {
        label: 'Flex leagues',
        filterType: 'flexLeagues',
        sportType: 'pickleball',
    },
    {
        label: 'Golden Ticket',
        filterType: 'events',
        data: { 'Golden Ticket': true },
        sportType: 'pickleball',
    },
    {
        label: 'Adult team pickleball',
        filterType: UT_ADULT_TEAM_PICKLEBALL,
        sportType: 'pickleball',
        isLeagues: true,
    },
    {
        label: 'Junior team pickleball',
        filterType: UT_JUNIOR_TEAM_PICKLEBALL,
        sportType: 'pickleball',
        isLeagues: true,
    },
    {
        label: 'Clubs',
        filterType: 'clubs',
        sportType: 'tennis,pickleball',
    },
];
